<template>
  <div class="item-features">
    <div class="row text-center">
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="AwardIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            Aligned with best practice
          </h4>
          <p class="card-text">
            Our products follow the best practises and ensure you get the best.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="ClockIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            10 Day Guranteed
          </h4>
          <p class="card-text">
            We offer 10 day gurantee on all our products. Your satisfaction is our happiness.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="ShieldIcon"
            size="35"
          />
          <h4 class="mt-2 mb-1">
            Secure by design
          </h4>
          <p class="card-text">
            Our templates have been prepared with security in mind and ensures your services are protected.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
