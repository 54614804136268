<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === null">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found in your selected items. Check
        <b-link class="alert-link" to="/apps/service-catalogue">
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card v-if="product">
      <b-card-body>
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="
              d-flex
              align-items-center
              justify-content-center
              mb-2 mb-md-0
            "
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="product.image_url"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
              />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>{{ product.name }}</h4>

            <!-- Product Brand -->
            <!-- <b-card-text class="item-company mb-0">
              <span>by</span>
              <b-link class="company-name">
                {{ product.brand }}
              </b-link>
            </b-card-text> -->

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">${{ product.price }}</h4>
            </div>

            <!-- Product Description -->
            <b-card-text v-html="product.description">{{ product.description }}</b-card-text>

            <hr />

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                @click="handleCartActionClick(product)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
              >
                <span>Add to cart</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
      <e-commerce-product-details-item-features />

      <!-- Static Content -->
      <!-- Slider: Related Products -->
      <e-commerce-product-details-related-products />
    </b-card>
  </section>
</template>

<script>
import { useRouter } from "@core/utils/utils";
import store from "@/store";
import { ref } from "@vue/composition-api";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ECommerceProductDetailsItemFeatures from "./ECommerceProductDetailsItemFeatures.vue";
import ECommerceProductDetailsRelatedProducts from "./ECommerceProductDetailsRelatedProducts.vue";
import { useEcommerceUi } from "../useEcommerce";

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,

    // SFC
    ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();

    const product = ref(null);
    product.value = store.state["app-ecommerce"].selectedProduct;
    if(!product.value.hasOwnProperty('name')) product.value = null

    const selectedColor = ref(null);

    return {
      // Fetched Product
      product,

      // UI
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
